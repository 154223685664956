import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gadget-requirements"
    }}>{`Gadget Requirements`}</h1>
    <blockquote>
      <p parentName="blockquote">{`When adding a gadget, you should consider all of the following items.`}</p>
    </blockquote>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Config Mode (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Edit Mode (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`View Mode (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Mobile Support (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Accessibility (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Conditional Visibility (UI,API,Forms)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Clientside Validation (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Serverside Validation (Forms)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Document List View (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Document List Filter (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Document List Sort (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`CSV Download Support (API)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Export to PDF Support (PDF)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Anonymous Forms Support (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Workflow Simulator Support (UI)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Workflow Notification Support (UI,Workflows)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Workflow Conditional Logic Support (UI,Workflows)`}</li>
    </ul>
    <h1 {...{
      "id": "creating-a-new-gadget"
    }}>{`Creating a New Gadget`}</h1>
    <p>{`When creating a new gadget, there are a few main places to pay attention to:`}</p>
    <h2 {...{
      "id": "builder-ui"
    }}>{`builder-ui`}</h2>
    <p>{`Gadgets live in `}<inlineCode parentName="p">{`app/src/formbot/gadgets`}</inlineCode>{` . Take a look at a simple one like the Text gadget to get a good sense of the
structure. The manifest file ties everything together, and gadgets are registered in `}<inlineCode parentName="p">{`app/src/formbot/index.jsx`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "builder-api-node"
    }}>{`builder-api-node`}</h2>
    <p>{`Gadgets are defined in `}<inlineCode parentName="p">{`server/gadgets`}</inlineCode>{` and exported from `}<inlineCode parentName="p">{`server/gadgets/index.js`}</inlineCode>{`. The main configuration options
are as follows:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`defaultValue`}</inlineCode>{`: Pretty straightforward - a good place to put a sane default.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`progressiveDisclosure`}</inlineCode>{`: Defines the rules specific to this gadget for progressive disclosure.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`validateShape`}</inlineCode>{`: Defines the required structure of the value.`}</li>
    </ul>
    <h2 {...{
      "id": "forms-api"
    }}>{`forms-api`}</h2>
    <p>{`Gadgets live in `}<inlineCode parentName="p">{`server/lib/gadgets`}</inlineCode>{` and exported from `}<inlineCode parentName="p">{`server/lib/gadgets/index.js`}</inlineCode>{`. There is unfortunately a lot
of repetition in the gadget definition defined here and in `}<inlineCode parentName="p">{`builder-api-node`}</inlineCode>{`. One big difference is that gadgets
defined here accept a `}<inlineCode parentName="p">{`validations`}</inlineCode>{` key for server-side validations. However, be forewarned that server-side validation
error messages do not currently get rendered by the client app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      